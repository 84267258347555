import React, { useEffect } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
  Divider
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import numberFormat from 'src/utils/numberFormat';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  tableCell: {
    padding: '7px 12px',
    whiteSpace: 'nowrap'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    background: theme.palette.info.dark,
    padding: '5px 12px'
  },
  body: {
    fontSize: 14,
    padding: '7px 12px',
    minWidth: '100px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& th': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}))(TableRow);

const Row = ({ className, ...rest }) => {
  const { row } = rest;
  const [rowData, setRowData] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const classes = useStyles()

  // Accepts the array and key
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {}); // empty object is the initial value for result object
  };

  React.useEffect(() => {
    setRowData(groupBy(row.sales_summary, "sd2_counter"))


  }, [])

  const findSum = (obj) => {
    let sum = 0;
    for (let property in obj) {
      console.log(obj[property])
      sum += obj[property];
    }
    console.log(sum)
    return sum;
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root} key={uuid()} style={open ? { background: '#eee' } : null}>
        <TableCell className={classes.tableCell} style={{ padding: '10px' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">{moment(new Date(row.sd1_date)).format('MMM DD')}</TableCell>
        <TableCell className={classes.tableCell} align="right">{numberFormat(row.sd1_total_amount)}</TableCell>
        <TableCell className={classes.tableCell} align="right">{numberFormat(row.sd1_total_due)}</TableCell>
        <TableCell className={classes.tableCell} align="right">{numberFormat(row.sd1_total_profit)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={open ? null : { paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {rowData ?
              Object.entries(rowData).map(([key, value]) => {
                return (
                  <div key={uuid()}>
                    <Typography variant="h6" gutterBottom component="p">
                      Bill Summary - {key}
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow key={uuid()}>
                          <StyledTableCell style={{ minWidth: '180px' }}>Bill Details</StyledTableCell>
                          <StyledTableCell align="right">Amount</StyledTableCell>
                          <StyledTableCell align="right">Due</StyledTableCell>
                          <StyledTableCell align="right">Profit</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.map((historyRow) => (
                          <StyledTableRow key={uuid()} style={historyRow.sd2_bill_status == 1 ? { background: '#ff928a' } : null}>
                            <StyledTableCell style={{ minWidth: '180px' }}>{historyRow.sd2_customer} <br /> Products Sold: {historyRow.sd2_products}</StyledTableCell>
                            <StyledTableCell align="right">
                              {numberFormat(historyRow.sd2_amount)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {numberFormat(historyRow.sd2_due)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {numberFormat(historyRow.sd2_margin)}
                            </StyledTableCell>
                          </StyledTableRow>))}
                        <StyledTableRow className={classes.root} style={{ background: '#64b5f6' }}>
                          <StyledTableCell className={classes.tableCell} align="right">Total</StyledTableCell>
                          <TableCell className={classes.tableCell} align="right">{numberFormat(value.reduce((acc, item) => (acc + Number(item.sd2_amount)), 0))}</TableCell>
                          <TableCell className={classes.tableCell} align="right">{numberFormat(value.reduce((acc, item) => (acc + Number(item.sd2_due)), 0))}</TableCell>
                          <TableCell className={classes.tableCell} align="right">{numberFormat(value.reduce((acc, item) => (acc + Number(item.sd2_margin)), 0))}</TableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                    <Divider style={{ margin: '15px 0' }} />
                  </div>
                )
              })
              : null
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
};

Row.propTypes = {
  className: PropTypes.string
};

export default Row;
