import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/app-icon.png"
      width="40"
      style={{ display: 'block', borderRadius: '16px', margin: '0 10px', border: '2px solid #fff' }}
      {...props}
    />
  );
};

export default Logo;
