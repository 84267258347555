import React from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import numberFormat from 'src/utils/numberFormat';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
}));

const Row = ({ className, ...rest }) => {
  const { row } = rest;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles()

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={uuid()}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.ssl1_supp_info}</TableCell>
        <TableCell align="right">{numberFormat(row.ssl1_bal_amount)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
              Invoice Summary
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow key={uuid()}>
                    <TableCell align="left" className={classes.tableCell}>Date</TableCell>
                    <TableCell align="left" className={classes.tableCell}>Info</TableCell>
                    <TableCell align="right" className={classes.tableCell}>Amount</TableCell>
                    <TableCell align="right" className={classes.tableCell}>Balance Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.payment_summary?.map((historyRow, index) => (
                    <TableRow key={uuid()}  style={{ background: index % 2 ? "#ddebf7" : "#fff" }}>
                      <TableCell align="left" className={classes.tableCell}>{moment(new Date(historyRow.ssl2_inv_date)).format('MMM DD')}</TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {historyRow.ssl2_inv_no}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {numberFormat(historyRow.ssl2_inv_amount)}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {numberFormat(historyRow.ssl2_bal_amount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  className: PropTypes.string
};

export default Row;
