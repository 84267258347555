import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Grid,
  makeStyles,
  TablePagination
} from '@material-ui/core';
import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cardheader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  }
}));
const ReorderDetails = ({ className, ...rest }) => {
  const classes = useStyles();

  const [dataCount, setdataCount] = React.useState(20);
  const [dataCountSetted, setdataCountStatus] = React.useState(false);

  
  const [alertMessage, setalertMessage] = React.useState('');

  const [reorderData, setReorderData] = React.useState(false);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_reorder_details.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {

        if (data.status == "success") {
          setReorderData(JSON.parse(decryptString(JSON.stringify(data.reorder_details))))
        }

        if (data.status == "failed") {
          setalertMessage(data.message)
        }
      }).catch(function (error) {
        console.log(error);
      })
    }, [])

  if (!dataCountSetted && reorderData?.length) {
    setdataCount(reorderData?.length)
    setdataCountStatus(true)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    !(reorderData) ? <Loader datafromparent={{errorMSG : alertMessage}} /> : <Container maxWidth={false} className={clsx(classes.root, className)}
      {...rest}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
    <Card
      className={clsx(className)}
      {...rest}
    >
      <CardHeader title="Reorder Products" className={classes.cardheader} />
      <Divider />
      <Box minWidth={300}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                Product Name
                </TableCell>
              <TableCell align="center">
                Current Stock
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reorderData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => (
              <TableRow
                hover
                key={uuid()}
              >
                <TableCell align="left">
                  {product.spr_proname}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={product.spr_stock}
                    size="small"
                    style={product.spr_stock < 1 ? {background:"red",color:"white"} : {background:"orange"}}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Card>
    </Grid>
      </Grid>
    </Container >
  );
};

ReorderDetails.propTypes = {
  className: PropTypes.string
};

export default ReorderDetails;
