import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Card,
  Grid,
  CardHeader,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TablePagination,
  Paper
} from '@material-ui/core';
import Row from 'src/views/reports/DayWisePurchase/Row';
import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cardheader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  },
  paper: {
    overflowX: 'auto',
  },
  tableCell: {
    padding: '16px 12px',
    whiteSpace: 'nowrap'
  }
}));

const DayWisePurchase = ({ className, ...rest }) => {
  const classes = useStyles();

  const [alertMessage, setalertMessage] = React.useState('');


  const [billData, setBillData] = React.useState('');
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_purchases_details.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {

        if (data.status == "success") {
          setBillData(JSON.parse(decryptString(JSON.stringify(data.bill_details))))
        }

        if (data.status == "failed") {
          setalertMessage(data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, [])



  const [dataCount, setdataCount] = React.useState(20);
  const [dataCountSetted, setdataCountStatus] = React.useState(false);

  if (!dataCountSetted && billData.purchases_details?.length) {
    setdataCount(billData.purchases_details.length)
    setdataCountStatus(true)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    !(billData) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> : <Container maxWidth={false} className={clsx(classes.root, className)}
      {...rest}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <Card>
            <CardHeader title="Date wise purchases report" className={classes.cardheader} />
            <Divider />
            <Paper className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}/>
                    <TableCell align="left" className={classes.tableCell}>
                      Date
                </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                     Total
                </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      Paid
                </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      Due
                </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billData.purchases_details?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <Row  row={row} key={uuid()} />
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={dataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>
    </Container >
  );
};

DayWisePurchase.propTypes = {
  className: PropTypes.string
};

export default DayWisePurchase;
