import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Backdrop, Button, makeStyles, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.white,
    },
}));

const Loader = ({...props}) => {

    const classes = useStyles();

    const [open] = React.useState(true);
    const [loader, setLoader] = React.useState(true);
    const navigate = useNavigate();

    setTimeout(() => {
        setLoader(false)
    }, 20000);

    return (
        <Backdrop className={classes.backdrop} open={open} >
            {loader ? <CircularProgress color="inherit" /> :
                <Alert variant="filled" severity="error" action={
                    <Button color="inherit" size="small" onClick={() => { navigate('/app/dashboard', { replace: true }) }}>
                        Go Back
                    </Button>
                }>
                    {props.datafromparent?.errorMSG ? props.datafromparent?.errorMSG : 'Network Error' }
                </Alert>
            }
        </Backdrop>
    );
};

export default Loader;
