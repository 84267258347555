import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink, useNavigate, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Cookies from 'js-cookie';

import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles, Card
} from '@material-ui/core';
import Page from 'src/components/Page';
import encryptString from 'src/utils/encryptString';

const { REACT_APP_API_URL } = process.env;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function isMobile() {
    var check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const authToken = localStorage.getItem('eToken');

  const classes = useStyles();
  const navigate = useNavigate();

  const styles = {
    root: {
      height: '100%',
      display: 'flex'
    },
    appIcon: {
      width: '70px',
      margin: 'auto',
      display: 'block'
    },
    tyobeLogo: {
      width: '100px',
      margin: '10px auto 5px',
      display: 'block'
    }
  };
  return (

    (isMobile() && authToken) ? <Navigate to="/app/dashboard" /> :
      <Page
        className={classes.root}
        title="Login | eManager for Smart Apps"
      >
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertStatus}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="center"
          style={{ minHeight: '100%' }}
        >
          <Grid
            item
            xs={12}
          >
            <img src="/static/app-icon.png" style={styles.appIcon} mb={1} />
            <Box mt={1} textAlign="center">
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                eManager for Smart Apps
                        </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}>
            <Container maxWidth="xs">
              <Formik
                initialValues={{
                  cid: '',
                  mobile: '',
                  password: '',
                  deviceUniqueID : Cookies.get('eManagerUniqueID')
                }}
                validationSchema={Yup.object().shape({
                  cid: Yup.string().max(255).required('Customer ID required'),
                  mobile: Yup.string().max(255).required('Mobile Number required'),
                  password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false)
                  fetch(process.env.REACT_APP_API_URL + '/smart_auth.php', {
                    method: 'POST',
                    body: encryptString(values)
                  })
                    .then(response => response.json())
                    .then(data => {
                      if (data.status == "active") {
                        setalertStatus('success')
                        setalertMessage(data.message)
                        setOpen(true)
                        navigate('/app/dashboard', { replace: true })
                        localStorage.setItem('eToken', JSON.stringify(data.token))
                      }
                      if (data.status == "pending") {
                        setalertStatus('warning')
                        setalertMessage(data.message)
                        setOpen(true)
                      }
                      if (data.status == "failed") {
                        setalertStatus('error')
                        setalertMessage(data.message)
                        setOpen(true)
                      }
                    }).catch(function (error) {
                      console.log(error);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.cid && errors.cid)}
                        fullWidth
                        helperText={touched.cid && errors.cid}
                        label="Customer ID"
                        margin="normal"
                        name="cid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="cid"
                        value={values.cid}
                        variant="filled"
                        autoComplete="off"
                      />
                      <TextField
                        error={Boolean(touched.mobile && errors.mobile)}
                        fullWidth
                        helperText={touched.mobile && errors.mobile}
                        label="Mobile Number"
                        margin="normal"
                        name="mobile"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="mobile"
                        value={values.mobile}
                        variant="filled"
                        autoComplete="off"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="filled"
                        autoComplete="off"
                      />
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Sign in now
                  </Button>
                      </Box>
                    </form>
                  )}
              </Formik>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}>
            <Box textAlign="center">
              <Typography
                color="textSecondary"
                variant="h6"
              >
                Powered by
                  </Typography>
              <img src="static/tyobe-logo.png" style={styles.tyobeLogo} />
              <Typography
                color="textSecondary"
                variant="body1"
              >
                www.tyobe.com
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Page>
  );
};

export default LoginView;
