import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { customerDataService } from 'src/services/customerData'
import numberFormat from 'src/utils/numberFormat';
import Loader from 'src/services/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardheader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  }
}));

const Account = () => {
  const classes = useStyles();
  const [customerData, setcustomerData] = React.useState('');

  React.useEffect(() => {
    customerDataService.getCustomerData().subscribe(customerData => {
      if (customerData) {
        setcustomerData(customerData)
      }
    })
  },[])

  return (
    (!customerData) ? < Loader /> : <Page
      className={classes.root}
      title="Business Profile"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Card>
              <CardHeader title="Business Details" className={classes.cardheader} />
              <CardContent>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  textAlign="center"
                >
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                  >
                    {customerData?.sc_customer_name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Customer ID : {customerData?.sc_cus_id}
                  </Typography>
                  <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {customerData?.sc_address}
                  </Typography>

                  {customerData?.sc_gstno ? <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    GST No : {customerData?.sc_gstno}
                  </Typography> : null}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Card>
              <CardHeader title="Licence Details" className={classes.cardheader} />
              <Divider />
              <CardContent>
                <Box
                  alignItems="left"
                  display="flex"
                  flexDirection="column"
                >
                  {customerData?.sc_pos_renewal_date ? <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    Software Renewal Date - {customerData?.sc_pos_renewal_date}
                  </Typography> : null}

                  {customerData?.sc_pos_renewal_charge > 0 ? <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    Renewal Amount - {numberFormat(customerData?.sc_pos_renewal_charge)}
                  </Typography> : null}


                  {customerData?.sc_pos_renewal_date ? <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    eManager Renewal Date - {customerData?.sc_emgr_renewal_date}
                  </Typography> : null}

                  {customerData?.sc_pos_renewal_charge > 0 ? <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    eManager Renewal Amount - {numberFormat(customerData?.sc_emgr_renewal_charge)}
                  </Typography> : null}
                </Box>
              </CardContent>
            </Card>


          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
