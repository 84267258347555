import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Badge,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  TrendingUp as TrendingUpIcon,
  Archive as ArchiveIcon,
  Settings as SettingsIcon,
  Info as InfoIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  LogOut as LogOutIcon,
  Package as PackageIcon,
  Users as UsersIcon,
  Server as ServerIcon,
  Layers as LayersIcon,
  FileText as FileTextIcon,
  List as ListIcon,
  Book as BookIcon
} from 'react-feather';
import StoreIcon from '@material-ui/icons/Store';
import NavItem from './NavItem';
import decryptString from 'src/utils/decryptString';
import { customerDataService } from 'src/services/customerData'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    user: 'all'
  },
  {
    href: '/app/sales',
    icon: TrendingUpIcon,
    title: 'Date Wise Sales',
    user: 'all'
  },
  {
    href: '/app/purchase',
    icon: FileTextIcon,
    title: 'Date Wise Purchases',
    user: 'all'
  },
  {
    href: '/app/expenses',
    icon: ListIcon,
    title: 'Date Wise Expenses',
    user: 'all'
  },
  {
    href: '/app/monthly',
    icon: ArchiveIcon,
    title: 'Monthly Sales',
    user: 'all'
  },
  {
    href: '/app/shift-wise',
    icon: BookIcon,
    title: 'Session wise Report',
    user: 'all'
  },
  {
    href: '/app/stock-details',
    icon: LayersIcon,
    title: 'Stock Details',
    user: 'all'
  },
  {
    href: '/app/reorder-details',
    icon: PackageIcon,
    title: 'Reorder Details',
    user: 'all'
  },
  {
    href: '/app/customer-payments',
    icon: UsersIcon,
    title: 'Customer Payment',
    user: 'all',
    datafromparent: ''
  },
  {
    href: '/app/supplier-payments',
    icon: TruckIcon,
    title: 'Supplier Payment',
    user: 'all',
    datafromparent: ''
  },
  {
    href: '/app/software-info',
    icon: ServerIcon,
    title: 'Software Info',
    user: 'all'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers',
    user: 'su'
  },
  {
    href: '/app/addons',
    icon: ShoppingBagIcon,
    title: 'Add-ons',
    user: 'su'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings',
    user: 'su'
  },
  {
    href: '/app/info',
    icon: InfoIcon,
    title: 'App Info',
    user: 'all'
  }
];


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  tyobeLogo: {
    width: '100px',
    margin: '10px auto 0px',
    display: 'block'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {

  const [customerData, setcustomerData] = React.useState('');
  const [logStatus, setlogOutStatus] = React.useState(false);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);

  const handleLogout = () => {
    localStorage.removeItem('eToken')
    navigate('/login', { replace: true })
  };

  const handleClose = () => {
    setlogOutStatus(false);
  };

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_customer_details.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == "success") {
          setcustomerData(JSON.parse(decryptString(JSON.stringify(data?.customer_details))))
          customerDataService.sendCustomerData(JSON.parse(decryptString(JSON.stringify(data?.customer_details))));
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, [])

  React.useLayoutEffect(() => {
    customerDataService.sendCustomerData(customerData);
  })
  const classes = useStyles();
  const location = useLocation();

  const logOutClick = () => {
    setlogOutStatus(true)
  }

  const profileClick = () => {
    navigate('/app/account', { replace: true })
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box>
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              {customerData?.sc_customer_name}
            </Typography>
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h6"
            >
              Customer ID: {customerData?.sc_cus_id}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={profileClick} color="primary" style={{ padding: '0' }}>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <StoreIcon />
                </Avatar>
              </StyledBadge>
            </IconButton>
          </Box>
        </Box>
        <Divider />

        <Box p={2}>
          <List>
            {items.map((item) => (
              customerData?.sc_access_type == 'su' ? <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              /> : (item.user == 'all') ? <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              /> : null
            ))}
            <Box p={2} textAlign="center">
              <Button
                color="primary"
                size="small"
                type="submit"
                margin="auto"
                variant="contained"
                onClick={logOutClick}
                mt={2}>
                <LogOutIcon /> Logout
          </Button>
            </Box>
          </List>
        </Box>
      </Box >

      <Box p={2} textAlign="center">
        <Typography
          color="textSecondary"
          variant="h6"
        >Powered by</Typography>
        <img src="/static/tyobe-logo.png" className={classes.tyobeLogo} />
        <Typography
          color="textSecondary"
          variant="body2"
        >www.tyobe.com</Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {logStatus ? <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog> : null}
      <Hidden lgUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;