import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Card,
  Grid,
  CardHeader,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Paper,
  TextField
} from '@material-ui/core';
import numberFormat from 'src/utils/numberFormat';

import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cardHeader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  },
  paper: {
    overflowX: 'auto',
  },
  tableCell: {
    padding: '16px 12px',
    whiteSpace: 'nowrap'
  }
}));

const MonthlySales = ({ className, ...rest }) => {
  const classes = useStyles();

  const [alertMessage, setalertMessage] = React.useState('');


  const [billData, setBillData] = React.useState('');
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_monthly_sales.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {

        if (data.status == "success") {
          setBillData(JSON.parse(decryptString(JSON.stringify(data.bill_details))))
        }

        if (data.status == "failed") {
          setalertMessage(data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, [])



  const [dateData, setDateData] = React.useState({ startDate: '', endDate: '', filterStartDate: '', filterEndDate: '' });
  const [totalAmount, setTotalAmount] = React.useState({ sales: 0, due: 0, profit: 0 });

  const [dataCountSetted, setdataCountStatus] = React.useState(false);

  if (!dataCountSetted && billData.sales_details?.length) {
    var dateArr = billData.sales_details.map(function (v) {
      return new Date(v.spm_date);
    });

    dateArr.sort(function (a, b) {
      return a.getTime() - b.getTime();
    });

    setDateData({ endDate: dateArr[dateArr.length - 1], startDate: dateArr[0], filterStartDate: moment(new Date(dateArr[dateArr.length - 1])).subtract(30, 'day').toDate(), filterEndDate: dateArr[dateArr.length - 1] })

    setdataCountStatus(true)
  }

  const getTotalVaues = () => {
    var finalData = { sales: 0, due: 0, profit: 0 }
    let tempFilter = billData.sales_details?.filter((a) => {
      var hitDates = a.spm_date || {};
      var date = new Date(hitDates);
      return date >= dateData.filterStartDate && date <= dateData.filterEndDate
    }).map((data) => {
      finalData.sales = Number(finalData.sales) + Number(data.spm_total_amount);
      finalData.due = Number(finalData.due) + Number(data.spm_total_due);
      finalData.profit = Number(finalData.profit) + Number(data.spm_total_profit);
    })

    setTotalAmount(finalData)
  }

  React.useMemo(() => {
    if (dateData.filterEndDate) {
      getTotalVaues()
    }
  }, [dateData])

  return (
    !(billData) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> : <Container maxWidth={false} className={clsx(classes.root, className)}
      {...rest}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={6}
        >
          <TextField
            id="from-date"
            label="From"
            type="date"
            defaultValue={moment(new Date(dateData.filterStartDate)).format('YYYY-MM-DD')}
            onChange={(e) => { setDateData({ ...dateData, filterStartDate: new Date(e.target.value) }) }}
            className={classes.textField}
            inputProps={{
              max: moment(new Date(dateData.filterEndDate)).format('YYYY-MM-DD'),
              min: moment(new Date(dateData.startDate)).format('YYYY-MM-DD')
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            id="to-date"
            label="To"
            type="date"
            max={moment(new Date(dateData.endDate)).format('YYYY-MM-DD')}
            defaultValue={moment(new Date(dateData.filterEndDate)).format('YYYY-MM-DD')}
            onChange={(e) => { setDateData({ ...dateData, filterEndDate: new Date(e.target.value) }) }}
            className={classes.textField}
            inputProps={{
              max: moment(new Date(dateData.endDate)).format('YYYY-MM-DD'),
              min: moment(new Date(dateData.filterStartDate)).format('YYYY-MM-DD')
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Card>
            <CardHeader title={"Total Sales : " + numberFormat(totalAmount.sales)} />
            <CardHeader title={"Total Due : " + numberFormat(totalAmount.due)} />
            <CardHeader title={"Total Profit : " + numberFormat(totalAmount.profit)} />
          </Card>

        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <Card>
            <CardHeader title="Sales report" className={classes.cardHeader} />
            <Divider />
            <Paper className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      Date
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      Total Sales
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      Total Due
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      Total Profit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billData.sales_details?.filter((a) => {
                    var hitDates = a.spm_date || {};
                    var date = new Date(hitDates);
                    return date >= dateData.filterStartDate && date <= dateData.filterEndDate
                  }).map((row, index) => (
                    <TableRow className={classes.root} key={uuid()} style={{ background: index % 2 ? "#ddebf7" : "#fff" }}>
                      <TableCell align="left" className={classes.tableCell}>{moment(new Date(row.spm_date)).format('MMM DD')}</TableCell>
                      <TableCell align="right" className={classes.tableCell}>{numberFormat(row.spm_total_amount)}</TableCell>
                      <TableCell align="right" className={classes.tableCell}>{numberFormat(row.spm_total_due)}</TableCell>
                      <TableCell align="right" className={classes.tableCell}>{numberFormat(row.spm_total_profit)}</TableCell>
                    </TableRow>
                  )
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </Container >
  );
};

MonthlySales.propTypes = {
  className: PropTypes.string
};

export default MonthlySales;
