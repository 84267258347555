import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  cardheader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  }
}));
const TopRunningProducts = ({ className, ...rest }) => {
  const classes = useStyles();

  const [dataCount, setdataCount] = React.useState(20);
  const [dataCountSetted, setdataCountStatus] = React.useState(false);

  if (!dataCountSetted && rest.datafromparent?.length) {
    setdataCount(rest.datafromparent?.length)
    setdataCountStatus(true)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Top Selling Products" className={classes.cardheader} />
      <Divider />
      <Box minWidth={300}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Rank
                </TableCell>
              <TableCell align="left">
                Product
                </TableCell>
              <TableCell align="center">
                Sales Count
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rest.datafromparent?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => (
              <TableRow
                hover
                key={uuid()}
              >
                <TableCell align="center">
                  {product.sts_rank}
                </TableCell>
                <TableCell align="left">
                  {product.sts_product_name}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={product.sts_sold_count}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[3, 10, 20]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
};

TopRunningProducts.propTypes = {
  className: PropTypes.string
};

export default TopRunningProducts;
