import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import SoftwareInfo from 'src/views/reports/SoftwareInfo';
import AppInfo from 'src/views/AppInfo';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import DayWiseSales from 'src/views/reports/DayWiseSales';
import MonthlySales from 'src/views/reports/MonthlySales';
import ShiftWise from 'src/views/reports/ShiftWise';
import DayWisePurchase from 'src/views/reports/DayWisePurchase';
import DayWiseExpenses from 'src/views/reports/DayWiseExpenses';




import SupplierPayments from 'src/views/reports/SupplierPayments';
import CustomerPayments from 'src/views/reports/CustomerPayments';
import ReorderDetails from 'src/views/reports/ReorderDetails';
import ProductDetails from 'src/views/reports/ProductDetails';


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: 'account', element: <AccountView /> },
      { path: 'sales', element: <DayWiseSales /> },
      { path: 'purchase', element: <DayWisePurchase /> },
      { path: 'expenses', element: <DayWiseExpenses /> },
      { path: 'monthly', element: <MonthlySales /> },
      { path: 'shift-wise', element: <ShiftWise /> },
      { path: 'customer-payments', element: <CustomerPayments /> },
      { path: 'supplier-payments', element: <SupplierPayments /> },
      { path: 'reorder-details', element: <ReorderDetails /> },
      { path: 'stock-details', element: <ProductDetails /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'addons', element: <ProductListView /> },
      { path: 'software-info', element: <SoftwareInfo /> },
      { path: 'info', element: <AppInfo /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/app/" /> }
    ]
  },
  { path: 'login', element: <LoginView /> },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: 'register', children: [
          { path: '/', element: <Navigate to="/" /> },
          { path: '/:ci/:s/:iv', element: <RegisterView /> }
        ]
      },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];
export default routes;
