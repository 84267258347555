import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import Page from 'src/components/Page';
import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
}));

const SoftwareInfo = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [syncStatus, setsyncStatus] = React.useState('');
  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');


  const [softwareInfo, setsoftwareInfo] = React.useState('');
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_software_info.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {

        if (data.status == "success") {
          setsoftwareInfo(JSON.parse(decryptString(JSON.stringify(data.software_details))))
        }

        if (data.status == "failed") {
          setalertStatus('error')
          setalertMessage(data.message)
          setOpen(true)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    !(softwareInfo) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> : <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={softwareInfo.ssi_version}
            title="Software Information"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={6}
              wrap="wrap"
            >
              <Grid
                className={classes.item}
                item
                md={4}
                sm={6}
                xs={12}
              >
                <List component="nav" className={classes.list} aria-label="mailbox folders">
                  <ListItem button>
                    <ListItemText primary="Online Backup" secondary={softwareInfo.ssi_online_backup} />
                  </ListItem>
                  <Divider />
                  <ListItem button divider>
                    <ListItemText primary="Offline Backup" secondary={softwareInfo.ssi_offline_backup} />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Database Size" secondary={softwareInfo.ssi_db_size} />
                  </ListItem>
                  <Divider light />
                  <ListItem button>
                    <ListItemText primary="Total Sales" secondary={softwareInfo.ssi_sales_count} />
                  </ListItem>
                  <Divider light />
                  <ListItem button>
                    <ListItemText primary="Total Purchase" secondary={softwareInfo.ssi_purchase_count} />
                  </ListItem>
                  <Divider light />
                  <ListItem button>
                    <ListItemText primary="Total Products" secondary={softwareInfo.ssi_products_count} />
                  </ListItem>
                  <Divider light />
                  <ListItem button>
                    <ListItemText primary="Total Customers" secondary={softwareInfo.ssi_customer_count} />
                  </ListItem>
                  <Divider light />
                  <ListItem button>
                    <ListItemText primary="Total Suppliers" secondary={softwareInfo.ssi_supplier_count} />
                  </ListItem>
                </List>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default SoftwareInfo;
