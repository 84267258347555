import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Link,
  Container,
  Grid,
  makeStyles,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  colors
} from '@material-ui/core';
import Page from 'src/components/Page';
import HourWiseSales from './HourWiseSales';
import moment from 'moment';
import { NavLink as RouterLink } from 'react-router-dom';
import TopRunningProducts from './TopRunningProducts';
import TopBills from './TopBills';
import CustomerBySource from './CustomerBySource';

import RefreshIcon from '@material-ui/icons/Refresh';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import numberFormat from 'src/utils/numberFormat';
import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';
import { customerDataService } from 'src/services/customerData'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  salesAvatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  purchaseAvatar: {
    backgroundColor: colors.amber[600],
    height: 56,
    width: 56
  },
  expenseAvatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  profitAvatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));



const Dashboard = () => {
  const [pageData, setPageData] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [syncStatus, setsyncStatus] = React.useState('');
  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');
  const [counter, setCounter] = React.useState(-1)
  const [customerData, setcustomerData] = React.useState('');

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (syncStatus && counter == -1) {
    setCounter(15)
  }

  if (counter == 0) {
    refreshPage()
  }

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_dashboard_details.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == "success") {
          setPageData(JSON.parse(decryptString(JSON.stringify(data?.page_details))))
        } else {
          setsyncStatus(data.status)
        }

        if (data.status == "failed") {
          setalertStatus('error')
          setalertMessage(data.message)
          setOpen(true)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, [])

  React.useLayoutEffect(() => {
    customerDataService.getCustomerData().subscribe(customerData => {
      if (customerData) {
        setcustomerData(customerData)
      }
    })
  })

  const classes = useStyles();
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    (!pageData && !syncStatus) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> :
      <Page
        className={classes.root}
        title="Dashboard"
      >
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertStatus}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Container maxWidth={false}>


          {pageData?.emgr_renewal_date ? <Grid
            item
            xs={12}
          >
            {moment(new Date(pageData?.emgr_renewal_date)).diff(moment(), 'days') < 0 ? <Card>
              <CardHeader title={"Your eManager licence was expired. Kindly contact +91 70 10 66 55 93 for further details"} />
            </Card> : moment(new Date(pageData?.emgr_renewal_date)).diff(moment(), 'days') < 5 ? <Card>
              <CardHeader title={"eManager will be expired in " + (moment(new Date(pageData?.emgr_renewal_date)).diff(moment(), 'days') + 1) + ' day(s)'} />
            </Card> : null}
          </Grid> : null}

          {moment(new Date(pageData?.emgr_renewal_date)).diff(moment(), 'days') < 0 ? null :
            <Grid
              container
              spacing={2}
            >
              {(pageData?.last_sync_date || pageData?.ms_sync_remark) ? <Grid
                item
                xs={12}
              >
                <Card>
                  <CardHeader title={"Last Sync : " + `${pageData?.ms_sync_remark ? pageData?.ms_sync_remark : moment(new Date(pageData?.last_sync_date)).format('MMM DD YYYY - hh:mm A')}`}
                    action={(
                      <Button
                        size="small"
                        variant="text"
                        style={{ marginTop: '6px' }}
                        onClick={refreshPage}
                      >
                        <RefreshIcon />
                      </Button>
                    )} />
                </Card>
              </Grid> : null}
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Link component={RouterLink} to="/app/sales"><Card>
                  <CardContent>
                    <Grid
                      container
                      justify="space-between"
                      spacing={2}
                    >

                      <Grid item>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Today Sales : {pageData?.dashboard_data?.sd_sales_count}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h3"
                        >
                          {pageData?.dashboard_data?.sd_sales_amount ? numberFormat(pageData?.dashboard_data?.sd_sales_amount) : null}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Avatar className={classes.salesAvatar}>
                          <ShoppingCartIcon />
                        </Avatar>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
              {customerData?.sc_platform == 6 ? <>
                {pageData?.dashboard_data?.sd_pending_kot ? <Grid
                  item
                  xs={6}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justify="space-between"
                        spacing={2}
                      >

                        <Grid item>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Pending KOTs
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="h3"
                          >
                            {pageData?.dashboard_data?.sd_pending_kot}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> : null}
                {pageData?.dashboard_data?.sd_dinein ? <Grid
                  item
                  xs={6}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justify="space-between"
                        spacing={2}
                      >

                        <Grid item>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Dine-In
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="h3"
                          >
                            {pageData?.dashboard_data?.sd_dinein}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> : null}
                {pageData?.dashboard_data?.sd_takeaway ? <Grid
                  item
                  xs={6}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justify="space-between"
                        spacing={2}
                      >

                        <Grid item>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Take Away
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="h3"
                          >
                            {pageData?.dashboard_data?.sd_takeaway}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> : null}
                {pageData?.dashboard_data?.sd_delivery ? <Grid
                  item
                  xs={6}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justify="space-between"
                        spacing={2}
                      >

                        <Grid item>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Delivery
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="h3"
                          >
                            {pageData?.dashboard_data?.sd_delivery}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> : null}
              </> : null}
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Link component={RouterLink} to="/app/purchase">
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justify="space-between"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Today Purchase : {pageData?.dashboard_data?.sd_pur_count}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="h3"
                          >
                            {pageData?.dashboard_data?.sd_pur_amount ? numberFormat(pageData?.dashboard_data?.sd_pur_amount) : null}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Avatar className={classes.purchaseAvatar}>
                            <ReceiptIcon />
                          </Avatar>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Link component={RouterLink} to="/app/expenses">
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justify="space-between"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Today Expense : {pageData?.dashboard_data?.sd_expen_count}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="h3"
                          >
                            {pageData?.dashboard_data?.sd_expen_amount ? numberFormat(pageData?.dashboard_data?.sd_expen_amount) : null}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Avatar className={classes.expenseAvatar}>
                            <ListAltIcon />
                          </Avatar>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Link component={RouterLink} to="/app/sales"><Card>
                  <CardContent>
                    <Grid
                      container
                      justify="space-between"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                        >
                          Today Profit
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h3"
                        >
                          {pageData?.dashboard_data?.sd_profit_amount ? numberFormat(pageData?.dashboard_data?.sd_profit_amount) : null}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Avatar className={classes.profitAvatar}>
                          <AttachMoneyIcon />
                        </Avatar>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card></Link>
              </Grid>

              {pageData?.hour_wise_sales ?
                <Grid
                  item
                  lg={8}
                  md={12}
                  xl={9}
                  xs={12}
                >
                  <HourWiseSales datafromparent={pageData?.hour_wise_sales} />
                </Grid> : null}

              {(pageData?.dashboard_data.sd_new_customers + pageData?.dashboard_data.sd_old_customers + pageData?.dashboard_data.sd_unknown_customers) > 0 ?
                <Grid
                  item
                  lg={8}
                  md={12}
                  xl={9}
                  xs={12}
                >
                  <CustomerBySource datafromparent={pageData?.dashboard_data} />
                </Grid> : null}


              {pageData?.top_bills ? <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <TopBills datafromparent={pageData?.top_bills} />
              </Grid> : null}

              {pageData?.top_selling_product ? <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <TopRunningProducts datafromparent={pageData?.top_selling_product} />
              </Grid> : null}

            </Grid>}
        </Container>
      </Page >
  );
};

export default Dashboard;
