import React from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import numberFormat from 'src/utils/numberFormat';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  tableCell: {
    padding: '8px 12px',
    whiteSpace: 'nowrap'
  }
}));

const Row = ({ className, ...rest }) => {
  const { row, index } = rest;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles()

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={uuid()} style={{ background: index % 2 ? "#ddebf7" : "#fff" }}>
        <TableCell className={classes.tableCell}>
        {row.spd_sess_no}<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>{moment(new Date(row.spd_opening)).format('MMM DD hh:mm A')}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{(row.spd_status).toLowerCase() != "active" ? moment(new Date(row.spd_closing)).format('MMM DD hh:mm A') : "Upto Now"}</TableCell>
        <TableCell align="right" className={classes.tableCell}>{row.spd_status}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography component={'span'} >
                <pre>{row.spd_report}</pre>
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  className: PropTypes.string
};

export default Row;
