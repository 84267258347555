import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 60
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/static/tyobe-logo.png"
            width="100"
            style={{ display: 'block', margin: '0 10px'}}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;