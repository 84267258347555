import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, yupToFormErrors } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Card, CardActionArea, CardMedia, CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import { values } from 'lodash';
import decryptString from 'src/utils/decryptString';
import encryptString from 'src/utils/encryptString';


var CryptoJS = require("crypto-js");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = () => {
  const styles = {
    statusImage: {
      width: '200px',
      margin: 'auto',
      display: 'block'
    },
    media: {
      height: 380,
    },
  };

  const { REACT_APP_API_URL } = process.env;

  const classes = useStyles();
  const navigate = useNavigate();
  const param = useParams();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  var otpSent = false


  const [registrationCompleted, setregistrationStatus] = React.useState('')

  const [showPasswordField, setshowPasswordField] = React.useState(false)
  const [open, setOpen] = React.useState(false);

  const [alertStatus, setalertStatus] = React.useState('');
  const [alertMessage, setalertMessage] = React.useState('');

  const [appData, setAppData] = React.useState({
    ma_store_url: "https://play.google.com/store/apps/details?id=com.ennavenum.emanager",
    ma_qr_url: "https://ennavenum.in/static/download_qr.png",
    ma_banner_url: "https://ennavenum.in/static/app-intro.jpg"
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  var urlData = {
    ct: CryptoJS.enc.Hex.parse(param.ci).toString(CryptoJS.enc.Utf8),
    s: param.s,
    iv: param.iv
  }

  var regData = decryptString(JSON.stringify(urlData))


  React.useLayoutEffect(()=>{
    fetch(process.env.REACT_APP_API_URL + '/check_user_status.php', {
      method: 'POST',
      body: JSON.stringify(urlData)
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == "active") {
          setalertStatus('success')
          setalertMessage(data.message)
        } else if (data.status == "pending") {
          setalertStatus('success')
          setalertMessage(data.message)
        }
        if (data.status != 'NA') {
          setregistrationStatus(data.status)
        }
        if (data.status == "failed") {
          setalertStatus('error')
          setalertMessage(data.message)
          setOpen(true)
        }
        if (data.app_assets){
          setAppData(data.app_assets)
        }
      }).catch(function (error) {
        console.log(error);
      });
  },[])


  var sendOTP = (postData) => {
    const requestOptions = {
      method: 'POST',
      body: encryptString(postData)
    };
    fetch(process.env.REACT_APP_API_URL + '/register_addon.php', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.message == "OTP sent successfully") {
          setalertStatus('success')
          setalertMessage(data.message)
          setOpen(true)
          setshowPasswordField(true)
        }
        if (data.status == "failed") {
          setalertStatus('error')
          setalertMessage(data.message)
          setOpen(true)
          if (data.customer_status && data.customer_status == "existing") {
            localStorage.setItem('eToken', data.token)
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Page
      className={classes.root}
      title="Registeration | eManager for Smart Apps"
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertStatus}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {registrationCompleted ? <Container maxWidth="sm">
          {registrationCompleted == "pending" ? <div>
            <img src="/static/status_pending.svg" style={styles.statusImage} />
            <Box mt={2} textAlign="center">
              <Typography color="textPrimary"
                variant="h4">{alertMessage}</Typography>
            </Box>
          </div> : null}
          {registrationCompleted == "active" ? <div>
            <img src="/static/status_active.svg" style={styles.statusImage} />
            <Box mt={2} textAlign="center">
              <Typography color="textPrimary"
                variant="h4">{alertMessage}</Typography>
              <a href={appData.ma_store_url} target="_blank">
                <img src="/static/play_store.png" style={styles.statusImage} />
              </a>
              <Typography color="textPrimary"
                variant="h5">Or Scan QR to download</Typography>
              <img src={appData.ma_qr_url} style={styles.statusImage} />
            </Box>


          </div> : null}
        </Container> :
          <Container maxWidth="lg">
            <Grid container
              justify="center"
              alignItems="center">
              <Grid item xs={12} md={10} lg={8}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      style={styles.media}
                      image={appData.ma_banner_url}
                      title="eManager for Smart Apps"
                    />
                    <CardContent>
                      <Formik
                        initialValues={{
                          cid: regData.cid,
                          addon: regData.addon,
                          mobile: '',
                          password: ''
                        }}
                        validationSchema={
                          Yup.object().shape({
                            mobile: Yup.string().max(255).required('Phone Number is required'),
                            mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                            password: Yup.string().max(255).required('Password is required')
                          })
                        }
                        onSubmit={(values, actions) => {
                          actions.setSubmitting(false)

                          const requestOptions = {
                            method: 'POST',
                            body: encryptString(values)
                          };

                          fetch(process.env.REACT_APP_API_URL + '/register_addon.php', requestOptions)
                            .then(response => response.json())
                            .then(data => {
                              if (data.status == "success") {
                                setalertStatus('success')
                                setalertMessage(data.message)
                                setOpen(true)
                                setregistrationStatus('pending')
                              }
                              if (data.status == "failed") {
                                setalertStatus('error')
                                setalertMessage(data.message)
                                setOpen(true)
                              }
                            }).catch(function (error) {
                              console.log(error);
                            });

                          // navigate('/app/dashboard', { replace: true });
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                        }) => (
                            <form onSubmit={handleSubmit}>
                              <Box mb={2}>
                                <Typography
                                  color="textPrimary"
                                  variant="h2"
                                >
                                  {registrationCompleted}
                      Welcome {regData.name},
                  </Typography>
                              </Box>
                              <TextField
                                name="cid"
                                value={values.cid}
                                type="hidden"
                              />
                              <TextField
                                name="addon"
                                value={values.addon}
                                type="hidden"
                              />
                              <TextField
                                error={Boolean(touched.mobile && errors.mobile)}
                                fullWidth
                                helperText={touched.mobile && errors.mobile}
                                label="Mobile Number"
                                margin="normal"
                                name="mobile"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="mobile"
                                value={values.mobile.trim()}
                                variant="outlined"
                              />

                              {!errors.mobile && !showPasswordField && values.mobile.length == 10 ?
                                <Button color="primary"
                                  onClick={() => { sendOTP(values) }}
                                >Send OTP</Button> : null}
                              { showPasswordField ? <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                helperText={touched.password && errors.password}
                                label="OTP"
                                margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="password"
                                value={values.password}
                                disabled={otpSent}
                                variant="outlined"
                              /> : null}


                              <Box
                                alignItems="center"
                                display="flex"
                                ml={-1}
                              >
                              </Box>

                              <Box my={2}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting || !showPasswordField}
                                  fullWidth
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                >
                                  Sign up now
                                </Button>
                              </Box>
                            </form>
                          )}
                      </Formik>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Container>
        }
      </Box>
    </Page >
  );
};

export default RegisterView;
