import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Card,
  CardContent,
  Container,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText, colors
} from '@material-ui/core';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import InfoIcon from '@material-ui/icons/Info';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
}));


const aboutcontent = {
  Tyobe: "<p>We a Bunch of engineers working hard on Retail POS, Medical Laboratory Software, Website, Web and Mobile development to help you out grow digitally on your business.</p><p><b style='margin-top: 15px;display: block;'>Contact:</b></p><p> +91 7010665593 | +91 8760824801</p><p>info@tyobe.com</p>",
  eManager: "<p>eManager is a application used for business owners to manage their business effectively.</p><p>Supported Desktop Application:- Smart POS, Smart Lab and Smart SIS.</p><p>For more information contact info@tyobe.com.</p>",
}
const AppInfo = () => {
  const classes = useStyles();
  const [aboutOpen, setOpenInfo] = React.useState(false);
  const [colorOpen, setColorOpen] = React.useState(false);
  const [appType, setAppType] = React.useState('');


  const openThemeDialog = () => {
    setColorOpen(true)
  }

  const openInfo = (app) => () => {
    if (app == 'tyobe') {
      setAppType('Tyobe')
    } else {
      setAppType('eManager')
    }
    setOpenInfo(true)
  }
  const handleClose = () => {
    setOpenInfo(false)
    setColorOpen(false)
  };

  const setThemeColor = (color) => () => {
    if (color == 'blue') {
      localStorage.setItem('appTheme', 'blue')
    } else if (color == 'dark') {
      localStorage.setItem('appTheme', 'dark')
    } else {
      localStorage.removeItem('appTheme')
    }
    setColorOpen(false)
    window.location.reload()
  }

  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={"eManager : v2.0.0"}
            title="Application Information"
          />
          <CardContent>
            <Grid
              container
              wrap="wrap"
            >
              <Grid
                className={classes.item}
                item
                md={4}
                sm={6}
                xs={12}
              >
                <List component="nav" className={classes.list}>
                  <ListItem onClick={openThemeDialog}>
                    <ListItemAvatar>
                      <Avatar>
                        <ColorLensIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="App Theme" />
                  </ListItem>
                  <Divider />
                  <ListItem onClick={openInfo('app')}>
                    <ListItemAvatar>
                      <Avatar>
                        <InfoIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="About Application" />
                  </ListItem>
                  <Divider />
                  <ListItem onClick={openInfo('tyobe')}>
                    <ListItemAvatar>
                      <Avatar>
                        <BusinessCenterIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="About Tyobe" />
                  </ListItem>
                </List>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Container>
        <Dialog
          open={aboutOpen}
          onClose={handleClose}
          scroll="true"
        >
          <DialogTitle>About {appType}</DialogTitle>
          <DialogContent dividers="true">
            <DialogContentText
              tabIndex={-1}
              dangerouslySetInnerHTML={{ __html: aboutcontent[appType] }}>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
          </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={colorOpen}
          onClose={handleClose}
          scroll="true"
        >
          <DialogTitle>Choose Theme {appType}</DialogTitle>
          <DialogContent dividers="true">
            <ListItem onClick={setThemeColor('blue')}>
              <ListItemAvatar>
                <div style={{ height: '30px', width: '30px', borderRadius: '50%', background: colors.indigo[700] }}></div>
              </ListItemAvatar>
              <ListItemText primary="Blue" />
            </ListItem>
            <Divider />
            <ListItem onClick={setThemeColor('default')}>
              <ListItemAvatar>
                <div style={{ height: '30px', width: '30px', borderRadius: '50%', background: colors.red[500] }}></div>
              </ListItemAvatar>
              <ListItemText primary="Red" />
            </ListItem>
            <Divider />
            <ListItem onClick={setThemeColor('dark')}>
              <ListItemAvatar>
                <div style={{ height: '30px', width: '30px', borderRadius: '50%', background: colors.blueGrey[900] }}></div>

              </ListItemAvatar>
              <ListItemText primary="Dark" />
            </ListItem>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
};
export default AppInfo;
