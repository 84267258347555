import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link, Note } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  row: {
    flexDirection: 'row',
    justifyContent: "space-around",
    fontSize: 10,
    border: '1px solid #ededed',
    borderTop: 'none',
    padding: 2
  },
  headerNote: {
    fontSize: 9,
    marginBottom: 20,
    color: '#000'
  },
  footerNote: {
    position: 'absolute',
    fontSize: 8,
    bottom: 15,
    left: 30,
    color: 'grey',
  },
  hBG: {
    backgroundColor: '#ededed',
  },
  header: {
    color: '#000',
    padding: '6px 5px'
  },
  description: {
    color: '#333',
    padding: '3px 5px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 15,
    right: 30,
    color: 'grey',
  }
});

// Create Document Component
const PdfGenerator = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={[styles.headerNote, { textAlign: 'right' }]} fixed>
          {props.customerName} | {(props.filteredVal.manVal && props.filteredVal.manVal != '' && props.filteredVal.manVal != "all_tyobe") ? props.filteredVal.manVal : "All Products"} | {(props.filteredVal.catVal && props.filteredVal.catVal != '' && props.filteredVal.catVal != "all_tyobe") ? props.filteredVal.catVal : "All Products"} | {moment().format('MMM DD YYYY')}
        </Text>
        <View style={[styles.row, styles.hBG]}>
          {props.dataSwitch.procode ? <Text style={[styles.header, { width: 120 }]}>Product Code</Text> : null}
          <Text style={[styles.header, { width: 200 }]}>Product Name</Text>
          {props.platform == 4 ?
            <>
              <Text align="left" style={[styles.header, { width: 120 }]}>Tag ID</Text>
              <Text align="left" style={[styles.header, { width: 120 }]}>Weight</Text>
              <Text align="left" style={[styles.header, { width: 120 }]}>HUID</Text>
            </> : null}

          {props.dataSwitch.purchase ? <Text style={[styles.header, { width: 80 }]}>Purchase Rate</Text> : null}
          {props.dataSwitch.sales ? <Text style={[styles.header, { width: 70 }]}>Sales Rate</Text> : null}
          {props.dataSwitch.stock ? <Text style={[styles.header, { width: 50 }]}>Stock</Text> : null}
        </View>
        {props.data.map(data => {
          return (
            (!(props.dataSwitch.nillstock && data.stock == 0)) ?
              <View style={styles.row} key={uuid()}>
                {props.dataSwitch.procode ? <Text style={[styles.description, { width: 120 }]}>{data.procode}</Text> : null}
                <Text style={[styles.description, { width: 200 }]}>
                  {data.proname}
                </Text>
                {props.dataSwitch.purchase ? <Text style={[styles.description, { width: 80 }]}>{data.purchase}</Text> : null}
                {props.platform == 4 ?
                  <>
                    <Text align="left" style={[styles.description, { width: 120 }]}>{data.batch}</Text>
                    <Text align="left" style={[styles.description, { width: 120 }]}>{data.grosswt}</Text>
                    <Text align="left" style={[styles.description, { width: 120 }]}>{data.huid}</Text>
                  </> : null}
                {props.dataSwitch.sales ? <Text style={[styles.description, { width: 70 }]}>{data.sales}</Text> : null}
                {props.dataSwitch.stock ? <Text style={[styles.description, { width: 50 }]}>
                  {data.stock}
                </Text> : null}
              </View>
              : null
          )
        })}

        <Text style={styles.footerNote} fixed>
          Generated by <Link src="https://play.google.com/store/apps/details?id=com.ennavenum.emanager">eManager</Link> for <Link src="https://www.tyobe.com/product/pos-billing-software">SmartPOS</Link>
        </Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>)
};

export default PdfGenerator;