import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  cardheader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  }
}));

const CustomerBySource = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [rest.datafromparent?.sd_new_customers, rest.datafromparent?.sd_old_customers, rest.datafromparent?.sd_unknown_customers],
        backgroundColor: [
          colors.green[500],
          colors.indigo[600],
          colors.red[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['New Customers', 'Old Customers', 'Unknown']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'New Customers',
      value: rest.datafromparent?.sd_new_customers,
      color: colors.green[500]
    },
    {
      title: 'Existing Customers',
      value: rest.datafromparent?.sd_old_customers,
      color: colors.indigo[600]
    },
    {
      title: 'Unknown',
      value: rest.datafromparent?.sd_unknown_customers,
      color: colors.red[600]
    }
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Customers Statistics"
        className={classes.cardheader}
      />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          mt={2}
        >
          {devices.map(({
            color,
            title,
            value
          }) => (
              <Box
                key={title}
                p={1}
                textAlign="center"
              >
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {title}
                </Typography>
                <Typography
                  style={{ color }}
                  variant="h2"
                >
                  {value}
                </Typography>
              </Box>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};

CustomerBySource.propTypes = {
  className: PropTypes.string
};

export default CustomerBySource;
