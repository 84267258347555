import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    createdAt: '27/03/2019',
    description: 'An Owners app which is used to view the Smart POS data Remotely',
    media: '/static/images/products/product_1.png',
    title: 'Smart App',
    status: 'Active'
  }
];
